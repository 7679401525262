import React from 'react'
import styled from 'styled-components'
import RemarkRenderer from 'components/RemarkRenderer'
import { mediaQuery } from 'utils/style'

const Container = styled.div`
  display: flex;

  /* default styles */
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0.5rem 0 2rem 0;
  }
  & li {
    line-height: 1.8rem;
  }

  /* style overrides */
  ${({ styles }) => styles};
  ${mediaQuery()}
`

function parseStyles(styles) {
  if (!styles) return ''
  return `& * {${styles.split('\n').join(';')}}`
}

export default ({ data }) => {
  const parsedStyles = parseStyles(data.styles)
  return (
    <Container id={data.hash} styles={parsedStyles}>
      <RemarkRenderer
        ast={data.content?.childMarkdownRemark?.htmlAst}
        clamp={data.clamp && 'auto'}
        style={{ width: '100%' }}
        {...(data.clamp
          ? {
              media: device => (device === 'mobile' ? 'max-height: 15em;' : ''),
            }
          : {})}
      />
    </Container>
  )
}
